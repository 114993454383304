@tailwind base;
@tailwind components;

.slider-thumb::-webkit-slider-thumb {
  @apply h-4 w-4 cursor-pointer appearance-none rounded-lg bg-teal-500;
}

.slider-thumb::-webkit-slider-thumb:hover {
  @apply bg-teal-700;
}

/* width */
::-webkit-scrollbar {
  @apply h-3 w-3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-gray-300;
}
::-webkit-scrollbar-thumb:hover {
  @apply rounded-lg bg-gray-400;
}

h2::before,
.anchor::before {
  display: block;
  content: ' ';
  margin-top: -192px;
  height: 192px;
  visibility: hidden;
  pointer-events: none;
}

input[type='date']:not(:focus):in-range::-webkit-datetime-edit-year-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-month-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-day-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #3F92D1;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

@tailwind utilities;
